<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>money-bags</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.045,7.988C16.091,9.4,18.75,12.8,18.75,15.863c0,3.107-3.361,5.625-6.75,5.625S5.25,18.97,5.25,15.863C5.25,12.8,7.909,9.4,9.955,7.988L8.4,4.281a.9.9,0,0,1,.416-1.27,10.2,10.2,0,0,1,6.363,0A.9.9,0,0,1,15.6,4.281Z"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="9.955"
      y1="7.988"
      x2="14.045"
      y2="7.988"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M4.5,20.738c-3,0-3.75-3-3.75-5.114A7.512,7.512,0,0,1,4.33,9.488L3.066,7.665a.75.75,0,0,1,.616-1.177H6"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M10.329,17.332a2.225,2.225,0,0,0,1.858.876c1.139,0,2.063-.693,2.063-1.548s-.924-1.546-2.063-1.546-2.062-.693-2.062-1.548.924-1.547,2.062-1.547a2.221,2.221,0,0,1,1.858.875"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.187"
      y1="18.208"
      x2="12.187"
      y2="19.238"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.187"
      y1="10.988"
      x2="12.187"
      y2="12.019"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M19.5,20.738c3,0,3.75-3,3.75-5.114a7.512,7.512,0,0,0-3.58-6.136l1.264-1.823a.75.75,0,0,0-.616-1.177H18"
    />
  </svg>
</template>
